.section {
  padding-top: 180px;
  padding-bottom: 40px;
  .text {
    h4 {
      font-size: 35px;
      line-height: 1.2;
      font-weight: 400;
    }

    p {
      font-size: 15px;
      line-height: 1.5;
      font-weight: 400;
      margin-top: 10px;
      margin-bottom: 30px;
    }
  }
  .contact {


    .item_contact {
      display: flex;
      grid-gap: 20px;
      margin-top: 20px;
      @media screen and (max-width: 972px) {
        flex-direction: column;
      }
    }



    .item {
      display: flex;
      flex-direction: column;
      justify-content: center;
      grid-gap: 5px;

      height: 230px;
      background: #f5f5f5;
      border-radius: 15px;
      width: 100%;
      padding-left: 30px;
      @media screen and (max-width: 972px) {
        height: max-content;
        padding: 20px;
      }

      h4 {
        font-size: 20px;
        line-height: 1.5;
        font-weight: 400;

        @media screen and (max-width: 430px) {
          margin-left: 0;
        }
      }

      p {
        font-size: 16px;
        line-height: 1.5;
        font-weight: 400;

        color: #888888;
        @media screen and (max-width: 430px) {
          margin-left: 0;
        }
      }

      .item_p {
        //margin-right: 120px;
        color: #888888;
      }
      .buttons {
        display: flex;
        grid-gap: 10px;

        @media screen and (max-width: 415px) {
          flex-direction: column;
        }

        @media screen and (max-width: 430px) {
          margin-left: 0;
        }

        .button {
          display: flex;
          border: 1px solid #000;
          border-radius: 5px;
          padding: 8px 20px 8px 20px;
          cursor: pointer;
          color: #000;
          span {
            display: flex;
            align-items: center;
            justify-content: center;

            svg {
              height: 10px;
              margin-left: 5px;
              @media screen and (max-width: 485px) {
                height: 10px;
              }
            }
          }

        }
      }
    }
  }

  .worktime {
    display: flex;
    grid-gap: 20px;
    margin-top: 20px;
    @media screen and (max-width: 972px) {
      flex-direction: column;
    }
    .item_contact {
      display: flex;
      grid-gap: 20px;
      margin-top: 20px;
      @media screen and (max-width: 972px) {
        flex-direction: column;
      }
    }
    .item {
      display: flex;
      flex-direction: column;
      justify-content: center;
      grid-gap: 5px;
      padding: 30px;

      @media screen and (max-width: 430px) {
        padding: 20px;
      }

      height: 230px;
      background: #f5f5f5;
      border-radius: 15px;
      width: 100%;

      @media screen and (max-width: 972px) {
        height: max-content;
      }

      ul {


        @media screen and (max-width: 430px) {
          margin-left: 0;
        }

        li {
          color: #888888;
        }
      }

      h4 {
        font-size: 20px;
        line-height: 1.5;
        font-weight: 400;

        @media screen and (max-width: 430px) {
          margin-left: 0;
        }
      }

      p {
        font-size: 16px;
        line-height: 1.5;
        font-weight: 400;

        color: #000;
        @media screen and (max-width: 430px) {
          margin-left: 0;
        }
      }

      .item_p {
        //margin-right: 120px;
      }
      .buttons {
        display: flex;
        grid-gap: 10px;
        margin-left: 30px;
        @media screen and (max-width: 430px) {
          margin-left: 0;
        }
        .button {
          display: flex;
          border: 1px solid #000;
          border-radius: 5px;
          padding: 8px 20px 8px 20px;
          span {
            display: flex;
            align-items: center;
            justify-content: center;

            svg {
              height: 10px;
              margin-left: 5px;
              @media screen and (max-width: 485px) {
                height: 10px;
              }
            }
          }

        }
      }
    }
  }
}