.title {

  display: flex;
  justify-content: center;
  padding-top: 120px;
  flex-direction: column;
  align-content: center;
  margin: 0 auto;

  h4 {
    font-size: 35px;
    line-height: 1.2;
    font-weight: 400;
    text-align: inherit;
  }

  p {
    color: #888888;
    font-size: 15px;
    line-height: 1.37;
    font-weight: 400;
  }


}

.section_big {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 40px;

  .bg {
    width: 100%;
    height: 298px;
    border-radius: 15px;
    background: #000;
    display: flex;
    justify-content: center;
    align-items: center;
    @media screen and (max-width: 1023px) {
      img {
        width: 85%;
      }
    }
  }

  h4 {
    font-size: 22px;
    line-height: 1.37;
    font-weight: 400;
    margin-top: 15px;
    color: #000;
  }

  span {
    font-size: 16px;
    font-weight: 400;
    margin-top: 5px;
    display: flex;
    cursor: pointer;
    color: #000;
    svg {
      height: 25px;
    }
  }
}

.section {
  display: flex;
  grid-gap: 20px;
  width: 100%;
  margin-top: 40px;

  @media screen and (max-width: 1023px) {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
  }

  span {
    margin-top: 15px;
    margin-left: 10px;
    display: flex;
    font-size: 16px;
    font-weight: 400;

    svg {
      height: 25px;
    }
    cursor: pointer;
  }


  a {
    width: 100%;

    @media screen and (max-width: 1023px) {
      width: 47%;
    }

    .item {
      width: 100%;
      span {
        color: #000;
      }
      div {

        background: #f5f5f5;
        border-radius: 15px;
        height: 148px;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        cursor: pointer;


        p {
          text-align: center;
        }
      }

      @media screen and (max-width: 1023px) {
        width: 100%;
      }
    }
  }




}