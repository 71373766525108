.section {
  padding-top: 140px;
  padding-bottom: 40px;

  .info {
    margin-top: 120px;
    h4 {
      font-size: 35px;
      line-height: 1.2;
      font-weight: 400;
      margin-bottom: 30px;
      text-align: center;
    }
    ul {
      list-style: disc;
      li {
        color: #808080;
        @media screen and (max-width: 1152px){
          margin-bottom: 10px;
        }
        &::marker {
          color: #808080;
          font-size: 1em;
        }
      }
    }
  }
  .name {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 50px;
    h4 {
      font-size: 35px;
      line-height: 1.2;
      font-weight: 400;
    }
  }

  .benefits {
    display: flex;
    grid-gap: 50px;
    @media screen and (max-width: 827px) {
      flex-direction: column;
    }
    .item {
      p {
        font-size: 15px;
        line-height: 1.5;
        font-weight: 400;
        margin-top: 15px;
      }
      .title {
        display: flex;
        align-items: center;
        h4 {
          font-size: 20px;
          line-height: 1.37;
          font-weight: 400;
          margin-left: 20px;
        }
        .image {
          background: #f5f5f5;
          width: 50px;
          height: 50px;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 10px;
          svg {
            height: 35px;
            width: 35px;
          }
        }
      }
    }

  }
}