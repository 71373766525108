.switch {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin-top: 50px;
  .switch_buttons {
    display: flex;
    justify-content: center;
    grid-gap: 20px;

    .button {
      display: flex;
      border: 1px solid #000;
      border-radius: 5px;
      padding: 5px;
      transition: all 0.3s ease;
      background: transparent;
      cursor: pointer;
      color: #000;
      padding-left: 15px;
      padding-right: 15px;
      &:hover {
      background: #000;
      color: #fff;
    }
    }
  }
}