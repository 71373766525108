.form {
    display: flex;
    grid-gap: 20px;
    margin-top: 20px;


    flex-direction: column;
    max-width: 500px;
    width: 100%;
    justify-content: center;


    @media screen and (max-width: 1026px){
      flex-direction: column;
    }
    @media screen and (max-width: 936px){

    }

    .button {
      display: flex;
      align-items: end;
      grid-gap: 20px;
      .send_button {
        display: flex;
        border: 1px solid #000;
        border-radius: 5px;
        padding: 10px;
        background: #000;
        cursor: pointer;
        span {
          display: flex;
          align-items: center;
          color: #fff;
          margin-left: 10px;
          opacity: 1;
          font-size: 16px;
          font-weight: 400;
          svg {
            margin-right: 10px;
            width: 25px;
            height: 25px;
            fill: #fff;
            margin-left: 10px;
          }
        }
      }
    }


    .form_group {
      display: flex;
      flex-direction: column;
      label {
        font-size: 15px;
        line-height: 1.375;
        padding-bottom: 10px;
        font-weight: 500;
        text-align: left;
      }
      input {
        transition: all .3s cubic-bezier(.21,.67,.58,1);
        color: #1b1b1c;

        display: block;
        width: 100%;
        border: 1px solid #bababa;
        min-height: 46px;
        line-height: 1.466;
        font-family: inherit;
        font-size: 15px;
        font-weight: 400;
        padding: 12px 25px 11px 15px;
        word-break: keep-all;
        border-radius: 4px;

        &:hover {
          border: 1px solid #888;
          outline: 0;
          outline-offset: 0;
        }
        &:active {
          border: 1px solid #888;
          outline: 0;
          outline-offset: 0;
        }
        &:focus-visible {
          border: 1px solid #888;
          outline: 0;
          outline-offset: 0;
        }

      }
    }
  }

.section {
  padding-top: 120px;
  padding-bottom: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  grid-gap: 30px;

  .selection {
    display: flex;
    flex-direction: column;
    grid-gap: 30px;
  }
  input {
    transition: all .3s cubic-bezier(.21,.67,.58,1);
    color: #1b1b1c;

    display: block;
    width: 100%;
    border: 1px solid #bababa;
    min-height: 46px;
    line-height: 1.466;
    font-family: inherit;
    font-size: 15px;
    font-weight: 400;
    padding: 12px 25px 11px 15px;
    word-break: keep-all;
    border-radius: 4px;

    &:hover {
      border: 1px solid #888;
      outline: 0;
      outline-offset: 0;
    }
    &:active {
      border: 1px solid #888;
      outline: 0;
      outline-offset: 0;
    }
    &:focus-visible {
      border: 1px solid #888;
      outline: 0;
      outline-offset: 0;
    }

  }
  h4 {
    font-size: 35px;
    line-height: 1.2;
    font-weight: 500;
  }

    .buttons {
      display: flex;
      flex-direction: column;
      grid-gap: 10px;
      align-self: flex-start;
      width: 100%;
      button {
        display: flex;
        border: 1px solid #000;
        border-radius: 5px;
        padding: 10px;
        background: #000;
        cursor: pointer;
        color: #fff;
        span {
          display: flex;
          align-items: center;
          color: #fff;
          margin-left: 10px;
          opacity: 1;
          font-size: 16px;
          font-weight: 400;
          svg {
            margin-right: 10px;
            width: 25px;
            height: 25px;
            fill: #fff;
            margin-left: 10px;
          }
        }

      }
    }

}
