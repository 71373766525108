.section {
  padding-top: 120px;
  padding-bottom: 40px;

  h1 {
    font-size: 30px;
    line-height: 1.2;
    font-weight: 400;
    text-align: center;
  }
  .product {
    display: flex;
    justify-content: center;
    padding-top: 50px;
    h4 {
      color: #808080;
      font-size: 25px;
      font-weight: 400;
    }

    .review {
      h5 {
        color: #000;
        font-size: 18px;
        font-weight: 400;
      }
      .form {
        display: flex;
        grid-gap: 20px;
        margin-top: 20px;
        flex-direction: column;
        @media screen and (max-width: 1026px){
          flex-direction: column;
        }
        @media screen and (max-width: 936px){

        }

        .button {
          display: flex;
          align-items: end;
          margin-top: 20px;
          cursor: pointer;
          .send_button {
            display: flex;
            border: 1px solid #000;
            border-radius: 5px;
            padding: 10px;
            background: #000;
            cursor: pointer;

            span {
              display: flex;
              align-items: center;
              color: #fff;
              margin-left: 10px;
              opacity: 1;
              font-size: 16px;
              font-weight: 400;
              svg {
                margin-right: 10px;
                width: 25px;
                height: 25px;
                fill: #fff;
                margin-left: 10px;
              }
            }
          }
        }


        .review_group {
          .form_group {
            display: flex;
            flex-direction: column;
            label {
              font-size: 15px;
              line-height: 1.375;
              padding-bottom: 10px;
              font-weight: 500;
              text-align: left;
            }
            input, textarea {
              transition: all .3s cubic-bezier(.21,.67,.58,1);
              color: #1b1b1c;

              display: block;
              width: 100%;
              border: 1px solid #bababa;
              min-height: 46px;
              line-height: 1.466;
              font-family: inherit;
              font-size: 15px;
              font-weight: 400;
              padding: 12px 25px 11px 15px;
              word-break: keep-all;
              border-radius: 4px;

              &:hover {
                border: 1px solid #888;
                outline: 0;
                outline-offset: 0;
              }
              &:active {
                border: 1px solid #888;
                outline: 0;
                outline-offset: 0;
              }
              &:focus-visible {
                border: 1px solid #888;
                outline: 0;
                outline-offset: 0;
              }

            }
          }
        }

      }
    }
    .product_item {
      background: #fff;
      border-radius: 15px;
      margin-bottom: 30px;
      height: auto;
      display: flex;

      @media screen and (max-width: 1023px) {
        flex-direction: column;
        height: auto;
      }

      .p_image {
        display: flex;
        justify-content: center;
        padding: 10px;
        height: 100%;
        max-height: 300px;
        align-items: center;
        @media screen and (max-width: 1023px) {
          max-width: unset;
        }

        max-width: 360px;
        width: 100%;
        img {
          height: 93%;
          width: auto;
          @media screen and (max-width: 1023px) {
            height: 300px;
          }

        }
      }

      .p_info {
        margin-right: 30px;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        @media screen and (max-width: 1023px) {
          padding-left: 30px;
          margin-right: 0;
          padding-right: 30px;
        }

        .send_button {
          display: flex;
          border: 1px solid #000;
          border-radius: 5px;
          padding: 10px;
          background: #000;
          cursor: pointer;
          margin-top: 50px;
          span {
            display: flex;
            align-items: center;
            color: #fff;
            margin-left: 10px;
            opacity: 1;
            font-size: 16px;
            font-weight: 400;
            svg {
              margin-right: 10px;
              width: 25px;
              height: 25px;
              fill: #fff;
              margin-left: 10px;
            }
          }
        }

        .code {
          padding-top: 20px;
          font-size: 12px;
          font-weight: 400;
          line-height: 1.37;
          color: #888;
          opacity: 100%;
        }

        .p_title {
          font-size: 24px;
          font-weight: 400;
          line-height: 1.37;
          color: #000;
          margin-top: 20px;
          display: flex;
          .reviews {
            margin-left: auto;
            color: #000;
            p {
              font-size: 18px;
              font-weight: 400;
              line-height: 1.37;

            }
          }

        }

        .p_desc {
          margin-top: 10px;
          font-size: 15px;
          font-weight: 400;
          line-height: 1.2;
        }
      }
    }

  }
  .items {
    padding-top: 50px;
    padding-bottom: 110px;
    display: flex;
    grid-gap: 20px;
    flex-wrap: wrap;
    justify-content: center;
    flex-direction: column;
    .item {
      display: flex;
      flex-direction: column;
      justify-content: center;
      background: #f5f5f5;
      height: max-content;
      border-radius: 15px;
      //max-width: 576px;
      width: 100%;
      padding: 30px;

      h4 {
        display: flex;
        align-items: center;
        font-size: 18px;
        line-height: 1.37;
        font-weight: 400;
        text-align: unset;
        span {
          margin-left: auto;
          font-size: 12px;
          line-height: 1.37;
          font-weight: 400;
          text-align: unset;
        }
      }

      .admin {
        display: flex;
        grid-gap: 20px;

        .avatar {
          display: flex;
          align-items: center;
          justify-content: center;
          height: 80px;
          min-width: 80px;
          img {
            width: 80px;
          }
        }

        .text {
          display: flex;
          flex-direction: column;
          grid-gap: 8px;

          .admin__name {
            font-size: 12px;
          }
        }
      }
    }
  }
}