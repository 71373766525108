.section {
  display: flex;
  margin-top: 70px;
  grid-gap: 20px;
  padding-bottom: 50px;
  @media (min-width: 309px) and (max-width: 424px) {
    grid-gap: 0;
  }
  .feedback {
    h5 {
      font-size: 20px;
      line-height: 1.2;
      font-weight: 400;
      margin: 0;
      @media (min-width: 309px) and (max-width: 424px) {
        margin-left: 5px;
      }
    }
    p {
      @media (min-width: 309px) and (max-width: 424px) {
        margin-left: 5px;
      }
      color: #888888;
      font-size: 15px;
      line-height: 1.37;
      font-weight: 400;
    }
    .social {
      display: flex;
      flex-direction: row;
      margin-top: 25px;
      grid-gap: 5px;
      span {
        color: #000;
      }
      .icon {
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 40px;
        width: 40px;
        background: #f5f5f5;
        border-radius: 5px;

        svg {
          height: 20px;
          width: 20px;
        }
      }
    }
  }

  button {
    display: flex;
    border: 1px solid #000;
    align-items: center;
    border-radius: 5px;
    padding: 5px;
    margin-right: 15px;
    transition: all .3s ease;
    background: transparent;
    cursor: pointer;
    @media screen and (max-width: 425px) {
      width: max-content;
      padding: 0;
      align-items: center;
      margin-right: 0;
    }

    svg {
      height: 25px;
      width: 35px;
      padding-right: 10px;
    }

    span {
      padding-right: 15px;
      padding-left: 15px;
      font-size: 16px;
    }

    &:hover {

      color: #fff;
      background: #000;

      .span {
        color: #fff;
      }
      svg {
        fill: #fff;
      }

    }
  }
}

.avatar_feedback {
  background-image: url("../assets/images/feedback.avif");
  height: 60px;
  width: 60px;
  border-radius: 50%;
  @media screen and (max-width: 425px) {
    min-height: 60px;
    min-width: 60px;
  }
  @media screen and (max-width: 370px){
    display: none;
  }
}
