.section {
  display: flex;
  width: 100%;
  max-height: 287px;
  height: 100%;
  background: #f5f5f5;
  border-radius: 15px;
  margin-top: 200px;
  margin-bottom: 50px;
  @media screen and (max-width: 532px) {
    flex-direction: column;
    max-height: unset;
  }

  @media screen and (max-width: 641px) {
    margin-top: 100px;
  }

  .text {
    width: 100%;
    flex-flow: row wrap;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    margin-left: 50px;

    .button {
      display: flex;
      border: 1px solid #000;
      border-radius: 5px;
      padding: 5px;
      background: #000;
      margin-top: 30px;

      span {
        display: flex;
        color: #fff;
        margin-left: 10px;

        svg {
          margin-right: 10px;
          margin-left: 10px;
          width: 25px;
          height: 25px;
          fill: #fff;
        }
      }
    }
    @media screen and (max-width: 927px) {
      margin: 10px;
    }
    div {
      @media screen and (max-width: 927px) {
        margin-right: 0;
      }
      margin-right: 100px;
    }

    h4 {
      font-weight: 400;
      font-size: 30px;

      @media screen and (max-width: 927px) {
        font-size: 20px;
        line-height: 1.2;
        font-weight: 400;
        width: max-content;
      }
    }
    p {
      font-size: 16px;
      line-height: 1.5;
      font-weight: 400;
      @media screen and (max-width: 830px) {
        font-size: 14px;
      }

      @media screen and (max-width: 532px) {
       padding-right: 20px;
      }
    }
  }

  .image {
    width: 100%;
    display: flex;
    align-items: end;

    .bg {
      background-image: url("../assets/images/33636553_336_q55.avif");
      height: 350px;
      width: 100%;
      background-repeat: no-repeat;
      background-position-x: 50%;
      background-position-y: 50%;
      position: relative;
      z-index: 1;
      top: 0;
      background-size: 350px;


      @media screen and (max-width: 641px) {
        background-position-y: 100%;
        height: 300px;
        right: 0;
        background-size: 240px;
      }

      @media screen and (max-width: 532px) {
        background-position-y: 100%;
        height: 200px;
        right: 0;
        background-size: 190px;
      }

      @media (min-width: 831px) and (max-width: 1176px) {
        background-position-y: 100%;
        max-height: 350px;
        right: 0;
        background-size: 300px;
      }

    }
  }
}