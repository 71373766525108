.section {
  display: flex;
  flex-direction: row;
  grid-gap: 40px;
  width: 100%;
  margin-top: 90px;
  @media screen and (max-width: 830px) {
    flex-direction: row;
    flex-wrap: wrap;
    grid-gap: 10px;
    justify-content: center;
  }
  div {

    @media screen and (max-width: 830px) {
      width: 38%;
      margin: 5px;

      &:last-child {
        width: 78%;
      }
    }
    background: #f5f5f5;
    border-radius: 15px;
    height: 171px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
    img {
      padding: 20px;

      @media (min-width: 309px) and (max-width: 424px) {
        padding: 5px;
      }
    }

    p {
      text-align: center;
      @media screen and (max-width: 830px) {
        word-break: break-word;
        word-wrap: break-word;
        margin: 5px;
      }
    }
  }

}