.cart_icon {
  border-radius: 50%;
  background: #000;
  height: 60px;
  width: 60px;
  max-height: 70px;
  max-width: 70px;
  position: fixed;
  top: 20%;
  right: 0;
  margin-right: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding: 10px;
  transition: all 0.5s;

  &:hover {
    height: 63px;
    width: 63px;
    transition: all 0.5s;
  }

  span {
    height: 20px;
    background: #f5f5f7;
    border-radius: 50%;
    width: 20px;
    position: absolute;
    right: 0;
    top: 0;
    color: #000;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
  }
  svg {
    fill: #fff;
    height: 25px;
    width: 25px;
    margin-right: 3px;
  }
}







