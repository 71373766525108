.section {
  padding-top: 180px;
  padding-bottom: 40px;

  .name {
    display: flex;
    @media screen and (max-width: 990px){
      flex-direction: column;

    }
    h4 {
      width: 100%;
      font-size: 35px;
      line-height: 1.2;
      font-weight: 400;
    }
    .desc {
      h5 {
        font-size: 22px;
        line-height: 1.37;
        font-weight: 400;
        margin-bottom: 10px;
      }
      span {
        cursor: pointer;
        display: flex;
        align-items: center;

        &:hover {
          color: #fb6590;
          fill: #fb6590;
        }
        svg {
          margin-left: 5px;
          height: 23px;
          width: 23px;

        }
      }
    }


  }

  .service {
    display: flex;
    margin-top: 100px;
    grid-gap: 50px;
    @media screen and (max-width: 990px){
      flex-direction: column;
      align-items: center;
    }

    .benefits {
      display: flex;
      flex-direction: column;
      grid-gap: 15px;
      width: 100%;
      @media screen and (max-width: 990px){
        align-items: center;
      }

      .item {
        display: flex;
        flex-direction: column;
        justify-content: center;
        background: #f5f5f5;
        height: 156px;
        border-radius: 15px;
        max-width: 576px;

        padding-right: 30px;
        padding-left: 30px;
        h4 {
          font-size: 22px;
          line-height: 1.37;
          font-weight: 400;
        }
        p {
          font-size: 15px;
          line-height: 1.5;
          font-weight: 400;
          margin-top: 10px;
        }
      }
    }
    .start {
      width: 100%;
      display: flex;
      align-items: flex-start;
    .start_section {
      display: flex;
      align-items: center;
      width: 100%;
      justify-content: center;
      flex-direction: column;
      .items {
        display: flex;
        margin-top: 30px;
        @media screen and (max-width: 505px){
          flex-direction: column;
        }

        .image {
          max-height: 100px;
          max-width: 130px;
        }
      }
      span {
        display: flex;
        align-items: center;
        font-size: 35px;
        line-height: 1.2;
        font-weight: 400;
        @media screen and (max-width: 505px){
          flex-direction: column;
        }
        strong {
          margin-left: 15px;
          color: #fff;
          background: linear-gradient(135deg, #b5d1ff 0%, #f57070 100%);
          border-radius: 10px;
          font-size: 22px;
          line-height: 1.5;
          font-weight: 400;
          padding: 13px;
        }
      }
    }
    }
  }
}