.popupFade {

  //display: none;

  &::before {
    content: '';
    background: #000;
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0.7;
    z-index: 9999;

  }
  .popup {

    position: fixed;

    width: 100%;
    height: 100%;
    overflow: auto;
    z-index: 99999;
    opacity: 1;

    padding-bottom: 150px;
    padding-top: 150px;

    .content {

      display: flex;
      grid-gap: 50px;
      justify-content: center;
      width: 100%;
      max-width: 800px;
      padding: 30px;
      background: #fff;

      position: relative;
      left: 50%;
      transform: translateX(-50%);

      @media screen and (max-width: 871px) {
        flex-direction: column-reverse;
      }

      .popupClose {
        position: absolute;
        top: 10px;
        right: 10px;
      }

      .closeCard {
        position: absolute;
        right: 0;
        top: 0;
        padding: 10px;
        cursor: pointer;
        svg {
          height: 16px;
          width: 16px;
        }
      }
      .data {
        width: 100%;
        display: flex;
        flex-direction: column;

        .hr2 {
          background: #d1d1d1;

          height: 1px;
          width: 100%;
          border: none;
        }

        h4 {
          font-size: 18px;
          line-height: 1.37;
          font-weight: 400;
        }
        h5 {
          font-size: 15px;
          line-height: 1.33;
          font-weight: 400;
        }

        .form {
          display: flex;
          flex-direction: column;
          grid-gap: 20px;
          margin-top: 20px;

          .button {
            display: flex;
            align-items: end;

            .send_button {
              display: flex;
              border: 1px solid #000;
              border-radius: 5px;
              padding: 10px;
              background: #000;
              cursor: pointer;
              span {
                display: flex;
                align-items: center;
                color: #fff;
                margin-left: 10px;
                opacity: 1;
                font-size: 16px;
                font-weight: 400;
                svg {
                  margin-right: 10px;
                  width: 25px;
                  height: 25px;
                  fill: #fff;
                  margin-left: 10px;
                }
              }
            }
          }

          .policy {
            opacity: .6;
            font-size: 13px;
            line-height: 16px;
            color: #060607
          }

          .form_group {
            display: flex;
            flex-direction: column;
            p {
              font-size: 15px;
              line-height: 1.375;
              padding-bottom: 10px;
              font-weight: 500;
              text-align: left;
            }
            label {
              font-size: 15px;
              line-height: 1.375;
              padding-bottom: 10px;
              font-weight: 500;
              text-align: left;
            }

            .delivery {
              display: flex;
              flex-direction: column;
              grid-gap: 10px;
              .item {
                cursor: pointer;
                transition: all .3s cubic-bezier(.21,.67,.58,1);
                color: #1b1b1c;
                background: #fff;
                display: block;
                width: 100%;
                border: 1px solid #bababa;
                min-height: 46px;
                line-height: 1.466;
                font-family: inherit;
                font-size: 15px;
                font-weight: 400;
                //padding: 12px 25px 11px 15px;
                padding-left: 10px;
                word-break: keep-all;
                border-radius: 4px;

                span {
                  display: flex;
                  align-items: center;
                  grid-gap: 10px;
                  color: #000;

                  h5 {
                    font-size: 15px;
                    font-weight: 400;
                    opacity: .7;

                  }
                  strong {
                    font-weight: 400;
                  }

                }
                p {
                  opacity: .7;
                }
                &:hover {
                  border: 1px solid #888;
                  outline: 0;
                  outline-offset: 0;
                }
                &:active {
                  border: 1px solid #888;
                  outline: 0;
                  outline-offset: 0;
                }
                &:focus-visible {
                  border: 1px solid #888;
                  outline: 0;
                  outline-offset: 0;
                }
              }

            }

            input {
              transition: all .3s cubic-bezier(.21,.67,.58,1);
              color: #1b1b1c;

              display: block;
              width: 100%;
              border: 1px solid #bababa;
              min-height: 46px;
              line-height: 1.466;
              font-family: inherit;
              font-size: 15px;
              font-weight: 400;
              padding: 12px 25px 11px 15px;
              word-break: keep-all;
              border-radius: 4px;

              &:hover {
                border: 1px solid #888;
                outline: 0;
                outline-offset: 0;
              }
              &:active {
                border: 1px solid #888;
                outline: 0;
                outline-offset: 0;
              }
              &:focus-visible {
                border: 1px solid #888;
                outline: 0;
                outline-offset: 0;
              }

            }
            .form_contact {
              p {
                font-size: 15px;
                line-height: 1.375;
                padding-bottom: 10px;
                font-weight: 500;
                text-align: left;

              }
              span {
                display: flex;
                align-items: center;

                svg {
                  height: 6px;
                  margin-left: auto;
                }
              }
              .choosecontact {
                .input {
                  cursor: pointer;
                  transition: all .3s cubic-bezier(.21,.67,.58,1);
                  color: #1b1b1c;
                  background: #fff;
                  display: block;
                  width: 100%;
                  border: 1px solid #bababa;
                  min-height: 46px;
                  line-height: 1.466;
                  font-family: inherit;
                  font-size: 15px;
                  font-weight: 400;
                  padding: 12px 25px 11px 15px;
                  word-break: keep-all;
                  border-radius: 4px;

                  span {
                    strong {
                      font-weight: 400;
                    }
                  }

                  &:hover {
                    border: 1px solid #888;
                    outline: 0;
                    outline-offset: 0;
                  }
                  &:active {
                    border: 1px solid #888;
                    outline: 0;
                    outline-offset: 0;
                  }
                  &:focus-visible {
                    border: 1px solid #888;
                    outline: 0;
                    outline-offset: 0;
                  }
                }
                .dropdown {
                  background: #fff;
                  color: #000;
                  //position: absolute;
                  width: 100%;
                  max-width: 345px;
                  width: 100%;
                  top: 400px;
                  cursor: pointer;
                  transition: opacity .18s cubic-bezier(.21,.67,.58,1),transform .3s cubic-bezier(.21,.67,.58,1);
                  box-shadow: 0 3px 10px rgba(0,0,0,.08);
                  z-index: 9999;
                  ul {
                    list-style: none;
                    display: flex;
                    flex-direction: column;
                    padding: 0;
                    li {
                      display: flex;
                      align-items: center;
                      height: 46px;
                      padding-left: 10px;
                      &:hover {
                        background: #f4f4f4;
                      }
                    }
                  }
                }
              }
            }

          }
        }
      }

      .items {
        width: 100%;
        display: flex;
        flex-direction: column;
        h4 {
          font-size: 18px;
          line-height: 1.33;
          font-weight: 400;
        }
        .products {
          display: flex;
          flex-direction: column;
          margin-top: 20px;
          .product {
            display: flex;
            grid-gap: 20px;
            .image {
              height: 70px;
              width: 70px;
              display: flex;
              justify-content: center;
              img {
                width: auto;
                height: 100%;
              }
            }
            .info {
              width: 100%;
              .title {
                display: flex;
                align-items: center;
                h4 {
                  font-size: 15px;
                  line-height: 20px;
                }

                span {
                  height: 20px;
                  width: 20px;
                  cursor: pointer;
                  display: flex;
                  align-items: center;
                  margin-left: auto;
                  svg {
                    height: 10px;
                    width: 10px;
                    fill: #a7a7a7;
                  }
                  &:hover {
                    svg {
                      fill: #000;
                    }
                  }
                }
              }
              p {
                font-size: 14px;
                line-height: 20px;
                margin-top: 8px;
                opacity: .57;
              }
              .options {
                display: flex;
                align-items: center;
                .count {
                  display: flex;
                  align-items: center;
                  grid-gap: 5px;
                  p {
                    font-size: 15px;
                    height: 24px;
                    color: #000;
                    opacity: 1;
                    width: 20px;
                  }
                  .increase {
                    cursor: pointer;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 24px;
                    height: 24px;
                    padding: 0;
                    font-size: 10px;
                    color: #000;
                    position: relative;
                    opacity: .35;
                    span {
                      display: flex;
                      width: 1em;
                      height: 2px;
                      background: #a7a7a7;
                    }
                  }
                  .decrease {
                    cursor: pointer;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 24px;
                    height: 24px;
                    padding: 0;
                    font-size: 10px;
                    color: #000;
                    position: relative;
                    opacity: .35;
                    span {
                      display: flex;
                      width: 1em;
                      height: 2px;
                      background: #a7a7a7;
                      &:last-child {
                        width: 2px;
                        height: 1em;
                        background: #a7a7a7;
                        position: relative;
                        left: -6px;
                      }
                    }
                  }
                }
                .price {
                  display: flex;
                  margin-left: auto;
                  align-items: flex-end;
                  .item_price {
                    font-size: 13px;
                    line-height: 20px;
                    opacity: .5;
                    margin-right: 6px;
                    white-space: nowrap;
                  }
                  .item_sum {
                    font-size: 16px;
                    line-height: 22px;
                    white-space: nowrap;
                  }
                }
              }
            }
          }
        }
        .subtotal {
          margin-top: 50px;
          display: flex;

          h4 {
            font-size: 16px;
            line-height: 1.33;
            font-weight: 400;
          }

          p {
            font-size: 16px;
            line-height: 1.33;
            font-weight: 400;
            margin-left: auto;
          }
        }
        .shipping {
          display: flex;
          margin-top: 10px;
          h4 {
            font-size: 16px;
            line-height: 1.33;
            font-weight: 400;
          }

          p {
            font-size: 16px;
            line-height: 1.33;
            font-weight: 400;
            margin-left: auto;
          }
        }

        .hr {
          margin: 20px 0;
          background: #000;
          opacity: .2;
          height: 1px;
          width: 100%;
          border: none;
        }

        .summary {
          display: flex;
          h4 {
            font-size: 18px;
            line-height: 1.33;
            font-weight: 500;
          }
          p {
            font-size: 18px;
            line-height: 1.33;
            font-weight: 500;
            margin-left: auto;
          }
        }
      }
    }

  }
}