.section {
  padding-top: 180px;
  padding-bottom: 40px;

  .credit {
    display: flex;
    justify-content: center;
    flex-direction: column;
    h4 {
      font-size: 30px;
      line-height: 1.2;
      font-weight: 400;
      text-align: center;
      padding-bottom: 50px;
    }
    .items {
      display: flex;
      grid-gap: 15px;
      width: 100%;
      flex-wrap: wrap;
      @media screen and (max-width: 990px){
        flex-direction: column;
        align-items: center;
      }
      .item {
        display: flex;
        flex-direction: column;
        justify-content: center;
        background: #f5f5f5;
        height: auto;
        border-radius: 15px;
        width: 49%;
        padding: 30px;

        @media screen and (max-width: 990px){
          width: 100%;
        }

        &:last-child {
          width: 100%;
          height: max-content;
        }
        h4 {
          font-size: 22px;
          line-height: 1.37;
          font-weight: 400;
          text-align: unset;
          padding-bottom: 0;
        }
        p {
          font-size: 15px;
          line-height: 1.5;
          font-weight: 400;
          margin-top: 10px;
        }

        ul {
          li {
            font-size: 15px;
            line-height: 1.5;
            font-weight: 400;
            margin-top: 10px;
          }
        }

      }

      .item_calc {
        display: flex;
        flex-direction: column;
        justify-content: center;
        background: #f5f5f5;
        height: auto;
        border-radius: 15px;
        width: 100%;
        padding: 30px;

        .tfdata {
          .tfinput {
            transition: all 0.3s cubic-bezier(0.21, 0.67, 0.58, 1);
            color: #1b1b1c;
            display: block;
            width: 100%;
            border: 1px solid #bababa;
            min-height: 46px;
            line-height: 1.466;
            font-family: inherit;
            font-size: 15px;
            font-weight: 400;
            padding: 12px 25px 11px 15px;
            word-break: keep-all;
            border-radius: 4px;
            outline: unset;
            max-width: 580px;
          }
        }
        .tfoplata {
          ul {
            display: flex;
            grid-gap: 10px;
            @media screen and (max-width: 661px) {
              flex-direction: column;
            }
            li {
              display: flex;
              border: 1px solid #000;
              border-radius: 5px;
              padding: 5px;

              transition: all 0.3s ease;
              background: transparent;
              cursor: pointer;
              color: #000;
              align-items: center;
              grid-gap: 15px;

              img {
                height: 30px;
              }
            }
          }
        }

        .slider_container {
          display: flex;
          flex-direction: column-reverse;
          grid-gap: 5px;
          margin-top: 10px;
          background: #fff;
          border-radius: 12px;
          padding-bottom: 5px;
          padding-right: 5px;

          .slider_title {
            font-size: 15px;
            color: #808080;
            padding-left: 5px;
          }

          .slider_box {
            display: flex;
            align-items: center;
            align-content: center;
            .slider_tag {
              font-size: 15px;
              color: #808080;
              padding-right: 5px;
            }
          }
        }

        .slider {
          -webkit-appearance: none;
          appearance: none;
          width: 100%;
          height: 8px;
          border-radius: 5px;
          background-color: #ddd;
          outline: none;
          //opacity: 0.7;
          transition: opacity 0.2s;
          margin-right: 10px;
        }

        .slider::-webkit-slider-thumb {
          -webkit-appearance: none;
          appearance: none;
          width: 20px;
          height: 20px;
          border-radius: 50%;
          background-color: #000;
          cursor: pointer;
        }

        .slider::-moz-range-thumb {
          width: 20px;
          height: 20px;
          border-radius: 50%;
          background-color: #000;
          cursor: pointer;
        }

        .slider_input {
          width: 100%;
          padding: 5px;
          border: 1px solid #ddd;
          border-radius: 4px;
        }

        .slider_value {
          display: inline-block;
          margin-left: 10px;
          font-weight: bold;
        }


        h4 {
          font-size: 22px;
          line-height: 1.37;
          font-weight: 400;
          text-align: unset;
          padding-bottom: 0;
        }
        p {
          font-size: 15px;
          line-height: 1.5;
          font-weight: 400;
          margin-top: 10px;
        }

        ul {
          li {
            font-size: 15px;
            line-height: 1.5;
            font-weight: 400;
            margin-top: 10px;
          }
        }

      }

    }
  }
  .faq {
    padding-top: 50px;
    .accordion {
      max-width: 600px;
      margin: 2rem auto;
    }
    h4 {
      padding-bottom: 50px;
      font-size: 30px;
      line-height: 1.2;
      font-weight: 400;
      text-align: center;
    }
  }
}

.creditpay {
  p {
    margin-top: 5px;
  }
  ul {
    list-style: disc;
    padding-left: 20px;
    li {
      margin-top: 10px;
      &::marker {
        color: #808080;
      }
    }
  }
}