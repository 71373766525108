.header {
  display: flex;
  justify-content: center;
  width: 100%;
  padding-top: 30px;
  padding-bottom: 30px;
  background: #fff;
  position: fixed;
  z-index: 99;
  align-items: center;

  .mobile_menu {
    position: fixed;
    z-index: 999;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: #fff;

    .close {
      display: flex;
      justify-content: flex-end;
      width: 100%;
      cursor: pointer;

      svg {
        height: 28px;
        width: 28px;
        margin: 20px
      }
    }

    .mobile_container {
      opacity: 0;
      animation: ani 1.2s forwards;

      width: 100%;
      display: flex;
      justify-content: center;
      flex-direction: column;

      .mobile_links {
        display: flex;
        grid-gap: 20px;
        flex-direction: row;
        justify-content: center;
        padding: 0 20px;
        flex-wrap: wrap;
        .mobile_link {
          font-size: 16px;
          font-weight: 400;
          line-height: 1.37;
          color: #000;
        }
      }
      .mobile_line {
        background: #f2f2f2;

        height: 2px;
        margin: 20px;
      }

      .mobile_ul {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        margin-left: 10px;
        margin-top: 10px;
        grid-gap: 15px;
        justify-content: center;

        .mobile_li {
          color: #000;
          font-size: 16px;
          font-weight: 500;
          line-height: 1.37;
          margin-top: 10px;

        }
      }
      .mobile_items {
        display: flex;
        flex-direction: row;
        justify-content: center;
        flex-wrap: wrap;
        grid-gap: 10px;

        .mobile_item {
          width: 45%;
          background: #f2f2f2;
          height: 66px;
          border-radius: 15px;
          display: flex;
          justify-content: center;
          align-items: center;
          color: #000;

          p {
            font-size: 15px;
            font-weight: 500;
          }
        }
      }
    }

  }

  @keyframes ani {
    0% {opacity: 0;}
    100% {opacity: 1;}
  }

  @keyframes anti-ani {
    0% {opacity: 1;}
    100% {opacity: 0;}
  }

  .burger {
    display: none;
    cursor: pointer;



    @media screen and (max-width: 1023px) {
      display: flex;
      height: 32px;
      width: 32px;
      margin-left: 20px;
    }
  }

  @media screen and (max-width: 425px) {
    justify-content: center;

  }

  .header_logo {
    margin-left: 30px;
    margin-right: 30px;

    img {
      height: 50px;
    }
    @media screen and (max-width: 1023px) {
      margin: 0 auto;
    }
  }

  .ul {
    display: flex;
    flex-direction: row;
    margin-left: 120px;
    margin-right: 30px;

    @media screen and (max-width: 1023px) {
      display: none;
    }

    @media (min-width: 1023px) and (max-width: 1240px) {
      margin-left: 0;
      margin-right: 0;
    }

    li {
      display: flex;
      align-items: center;
      margin-right: 10px;
      margin-left: 10px;
      font-size: 15px;
      font-weight: 400;
      font-style: normal;
      line-height: 1.37;
      letter-spacing: 0;
      opacity: 1;
      color: #000;
      cursor: pointer;
      //transition: all .3s ease;
      text-decoration-color: rgba(0, 0, 0, 0.5);

      &:hover {
        text-underline-offset: 8px;
        text-decoration-line: underline;

      }

      svg {
        height: 10px;
        margin-left: 5px;
      }
    }

    .dropdown {
      position: absolute;
      display: flex;
      flex-direction: column;
      width: 170px;
      background: #fff;
      top: 80px;
      box-shadow: 1px 1px 30px 1px rgba(0,0,0,0.1);
      padding-top: 5px;
      padding-bottom: 5px;
      animation: ani 0.35s forwards;
      li {
        color: #888888;
        padding-top: 10px;
        padding-bottom: 10px;
        padding-left: 0;
        padding-right: 0;
        margin-left: 0;
        margin-right: 0;
        text-decoration: none;
        span {
          margin-left: 20px;
        }
        &:hover {
          background: #EDEDED;
          color: #000;
        }
      }
    }
  }

  .button {
    display: flex;
    border: 1px solid #000;
    border-radius: 5px;
    padding: 5px;
    margin-left: 40px;
    transition: all .3s ease;
    background: transparent;
    cursor: pointer;
    color: #000;
    @media (min-width: 1024px) and (max-width: 1135px) {
      margin-left: 0;
    }

    @media screen and (max-width: 1023px) {
      margin-left: 0;
      margin-right: 20px;
    }

    @media screen and (max-width: 485px) {
      border: unset;
      padding: 0;
      margin-left: 0;
      height: 32px;
      width: 32px;
      margin-right: 20px;
    }

    svg {
      height: 25px;
      @media screen and (max-width: 485px) {
        height: 32px;
      }
    }

    span {
      padding-right: 15px;
      font-size: 16px;
      @media screen and (max-width: 485px) {
        display: none;
      }
    }

    &:hover {

      color: #fff;
      background: #000;

      svg {
        fill: #fff;
      }
    }
  }
}

