.section {
  padding-top: 180px;
  padding-bottom: 40px;

  .garant {

    .question {
      padding-top: 50px;
      display: flex;
      justify-content: center;
      font-size: 17px;
      line-height: 25px;
      font-weight: 400;
    }
    .legal {
      display: flex;
      align-items: center;
      flex-direction: column;
      padding-top: 50px;

      h4 {
        font-size: 30px;
        line-height: 1.37;
        font-weight: 400;
      }

      .items {
        display: flex;
        grid-gap: 50px;
        padding-top: 50px;
        width: 100%;
        @media screen and (max-width: 991px) {
          flex-direction: column;
          //width: 70%;
        }
        .item {
          background: #f5f5f5;
          border-radius: 15px;
          padding: 30px;
          width: 100%;
          height: max-content;

          h5 {
            font-size: 22px;
            line-height: 1.37;
            font-weight: 400;
          }
          ul {
            li {
              margin-top: 10px;
              font-size: 15px;
              line-height: 1.5;
              font-weight: 400;

              a {
                color: #000;
              }
            }
          }
        }
      }

    }
  }
}