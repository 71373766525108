.about {
  display: flex;

  @media screen and (max-width: 1111px) {
    flex-direction: column-reverse;
  }
  .img {
    img {
      height: 234px;
      min-width: 300px;
    }
    @media screen and (max-width: 1111px) {
      height: auto;
      width: auto;
      align-self: flex-end;
    }
  }

  div {
    display: flex;
    align-items: flex-start;
    @media screen and (max-width: 1111px) {
      flex-direction: column;
    }
    .button {
      display: flex;
      border: 1px solid #fff;
      border-radius: 5px;
      padding: 5px;
      margin-left: 40px;
      transition: all .3s ease;
      color: #fff;
      width: max-content;
      justify-content: center;
      height: max-content;
      background: transparent;
      cursor: pointer;

      @media screen and (max-width: 1111px) {
        margin-left: 20px;
      }

      @media (min-width: 1112px) and (max-width: 1187px) {
        margin-right: 20px;
      }

      svg {
        height: 25px;
        width: 30px;
        fill: #fff;
      }

      span {
        padding-right: 15px;
        padding-left: 10px;
        font-size: 16px;
      }

    }
  }
}

.title {
  color: #fff;
  font-size: 35px;
  line-height: 1.37;
  font-weight: 400;
  padding-top: 80px;
  padding-bottom: 20px;
  @media screen and (max-width: 1175px) {
    margin: 20px;
    font-size: 31px;
    padding-top: 40px;
    padding-bottom: 0;
  }
}

.desc {
  color: #fff;
  font-size: 16px;
  line-height: 1.5;
  font-weight: 400;
  display: flex;
  align-items: center;
  margin-right: 40px;
  @media screen and (max-width: 1111px) {
    margin-left: 20px;
    margin-right: 20px;
  }
}

.about_button {

  @media screen and (max-width: 1111px) {
    margin-right: auto;
    position: relative;
    top: 100px;
  }


}



