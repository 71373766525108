.section {
  padding-top: 180px;
  padding-bottom: 40px;

  .head {
    display: flex;
    flex-direction: column;

    h4 {
      font-size: 30px;
      line-height: 1.2;
      font-weight: 400;
      text-align: center;
    }

    p {
      font-size: 17px;
      line-height: 25px;
      font-weight: 400;
      margin-top: 50px;
    }

    ul {
      list-style: disc;
      padding-left: 15px;
      padding-top: 10px;
      li {

        color: #808080;
        &::marker {
          color: #808080;
        }
      }
    }
  }
  .data {
    padding-top: 100px;
    h4 {
      font-size: 30px;
      line-height: 1.2;
      font-weight: 400;
      text-align: center;
      padding-bottom: 50px;
    }

    p {
      font-size: 17px;
      line-height: 25px;
      font-weight: 400;

    }

    ul {
      list-style: disc;
      padding-left: 15px;
      padding-top: 10px;
      padding-bottom: 20px;
      li {

        color: #808080;
        &::marker {
          color: #808080;
        }
      }
    }
  }
  .order {
    padding-top: 100px;
    h4 {
      font-size: 30px;
      line-height: 1.2;
      font-weight: 400;
      text-align: center;
      padding-bottom: 50px;
    }

    p {
      font-size: 17px;
      line-height: 25px;
      font-weight: 400;
    }

    .items {
      display: flex;
      grid-gap: 50px;
      padding-top: 50px;

      @media screen and (max-width: 990px){
        flex-direction: column;
      }
      .item {
        background: #f5f5f5;
        padding: 30px;
        display: flex;
        flex-direction: column;
        border-radius: 15px;
        height: max-content;
        h4 {
          font-size: 22px;
          line-height: 1.37;
          font-weight: 400;
          padding-bottom: 0;
          text-align: unset;
        }

        ul {
          li {
            font-size: 15px;
            line-height: 1.5;
            font-weight: 400;
            margin-top: 10px;
          }
        }
      }
    }
  }

  .faq {
    padding-top: 100px;
    h4 {
      font-size: 30px;
      line-height: 1.37;
      font-weight: 400;
      text-align: center;
    }

    .service {
      display: flex;
      margin-top: 100px;
      grid-gap: 50px;
      @media screen and (max-width: 990px){
        flex-direction: column;
      }

      .benefits {
        display: flex;
        flex-direction: column;
        grid-gap: 15px;
        width: 100%;
        @media screen and (max-width: 990px){
          align-items: center;
        }
        .item {
          display: flex;
          flex-direction: column;
          justify-content: center;
          background: #f5f5f5;
          height: auto;
          border-radius: 15px;
          max-width: 576px;

          padding: 30px;

          h4 {
            font-size: 22px;
            line-height: 1.37;
            font-weight: 400;
            text-align: unset;
          }
          p {
            font-size: 15px;
            line-height: 1.5;
            font-weight: 400;
            margin-top: 10px;
          }
        }
      }
      .start {
        width: 100%;
        display: flex;
        align-items: flex-start;
        .start_section {
          display: flex;
          align-items: center;
          width: 100%;
          justify-content: center;
          flex-direction: column;
          .items {
            display: flex;
            margin-top: 30px;
            .image {
              max-height: 100px;
              max-width: 130px;
            }
          }
          span {
            display: flex;
            align-items: center;
            font-size: 35px;
            line-height: 1.2;
            font-weight: 400;
            strong {
              margin-left: 15px;
              color: #fff;
              background: linear-gradient(135deg, #b5d1ff 0%, #f57070 100%);
              border-radius: 10px;
              font-size: 22px;
              line-height: 1.5;
              font-weight: 400;
              padding: 13px;
            }
          }
        }
      }
    }
  }
}