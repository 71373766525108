*,
*::before,
*::after {
  box-sizing: border-box;
}

body,
h1,
h2,
h3,
h4,
p,
ul[class],
ol[class],
li,
figure,
figcaption,
blockquote,
dl,
dd {
  margin: 0;
}


body {
  min-height: 100vh;
  scroll-behavior: smooth;
  text-rendering: optimizeSpeed;
  line-height: 1.5;
  font-family: 'Roboto', sans-serif;
  overscroll-behavior: contain;
}


ul[class],
ol[class] {
  list-style: none;
}


a:not([class]) {
  text-decoration-skip-ink: auto;
}


img {
  max-width: 100%;
  display: block;
}


article > * + * {
  margin-top: 1em;
}


input,
button,
textarea,
select {
  font: inherit;
}

@media (prefers-reduced-motion: reduce) {
  * {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important;
  }
}

:global {
  .error-message {
    font-size: 12px;
  }

  ul {
    padding: 0;
    margin: 0;
    list-style: none;
  }

  a {
    text-decoration: none;
  }

  ol[class] {
    padding: 0;
  }

  .container {
    display: flex;
    max-width: 1150px;
    width: 100%;
    flex-direction: column;
  }

  .wrapper {
    display: flex;
    justify-content: center;

    @media screen and (max-width: 1175px) {
      margin-right: 20px;
      margin-left: 20px;
    }


  }

  @media screen and (max-width: 1175px) {
    .full-background {
      margin-right: 0 !important;
      margin-left: 0 !important;

    }
  }

  .each-slide-effect {
    div {
      display: flex;
      align-items: center;
      justify-content: center;
      background-size: cover;
      height: 350px;
      width: 340px;
    }
  }

  .each-slide-effect {
    span {
      padding: 20px;
      font-size: 20px;
      background: #efefef;
      text-align: center;
    }
  }

  .slider-about {
    width: 100%;
    div {
      width: 100%;
    }
  }




}
