.section {
  padding-top: 180px;
  padding-bottom: 40px;

  .info {
    margin-top: 120px;

    @media screen and (max-width: 1222px){
      padding: 20px;
    }

    p {
      font-size: 15px;
      line-height: 1.5;
      font-weight: 400;
      margin-bottom: 30px;
      text-align: center;
    }
    ul {
      list-style: disc;
      li {
        color: #808080;
        display: flex;
        align-items: center;
        grid-gap: 10px;
        img {
          height: 20px;
        }
        @media screen and (max-width: 1152px){
          margin-bottom: 10px;
        }

        &::marker {
          color: #808080;
          font-size: 1em;
        }
      }
      .moreul {
        list-style: circle;
        padding-left: 15px;
        margin-top: 5px;
        li {
          @media screen and (max-width: 1152px){
            margin-bottom: 10px;
          }
          color: #808080;
          &::marker {
            color: #808080;
            font-size: 1em;
          }
        }
      }
    }
  }
  .map {
    padding-top: 100px;
    display: flex;

    div {
      width: 100%;
      @media screen and (max-width: 976px){
        display: none;
        &:last-child {
          display: flex;
          flex-direction: column;
        }
      }

      svg {



        path {
          transition: all 0.3s ease;
          &:hover {
            fill: #888888;
          }
        }
      }
      .package {
        @media screen and (max-width: 976px) {
          display: flex;
          flex-direction: column;
        }
        h4 {
          font-size: 30px;
          line-height: 1.37;
          font-weight: 400;
          @media screen and (max-width: 976px) {
            text-align: center;
          }
        }
        p {
          font-size: 15px;
          line-height: 1.5;
          font-weight: 400;
          @media screen and (max-width: 976px) {
            text-align: center;
          }
        }
        .delivery_sites {
          ul {
            list-style: disc;
            padding-left: 20px;
            @media screen and (max-width: 976px) {
              display: flex;
              flex-wrap: wrap;
              justify-content: center;
              grid-gap: 30px;
            }
            li {
              color: #808080;
              margin-top: 10px;

              a {
                color: #808080;
              }
              &::marker {
                color: #808080;
              }
            }
          }
        }
      }
    }
  }
  .service {
    display: flex;
    margin-top: 100px;
    grid-gap: 50px;

    @media screen and (max-width: 1018px) {
      flex-direction: column;
      align-items: center;
    }

    .benefits {
      display: flex;
      flex-direction: column;
      grid-gap: 15px;
      width: 100%;
      @media screen and (max-width: 1018px) {
        flex-direction: column;
        align-items: center;
      }
      h4 {
        font-size: 30px;
        line-height: 1.37;
        font-weight: 400;
      }
      .item {
        display: flex;
        flex-direction: column;
        justify-content: center;
        background: #f5f5f5;
        height: auto;
        border-radius: 15px;
        max-width: 576px;
        width: 100%;
        padding: 30px;

        h4 {
          font-size: 22px;
          line-height: 1.37;
          font-weight: 400;
        }
        p {
          font-size: 15px;
          line-height: 1.5;
          font-weight: 400;
          margin-top: 10px;
        }
      }
    }
    .start {
      width: 100%;
      display: flex;
      align-items: flex-start;
      .start_section {
        display: flex;
        align-items: center;
        width: 100%;
        justify-content: center;
        flex-direction: column;
        .items {
          display: flex;
          margin-top: 30px;
          .image {
            max-height: 100px;
            max-width: 130px;
          }
        }
        span {
          display: flex;
          align-items: center;
          font-size: 35px;
          line-height: 1.2;
          font-weight: 400;
          strong {
            margin-left: 15px;
            color: #fff;
            background: linear-gradient(135deg, #b5d1ff 0%, #f57070 100%);
            border-radius: 10px;
            font-size: 22px;
            line-height: 1.5;
            font-weight: 400;
            padding: 13px;
          }
        }
      }
    }
  }
  .garant {
    padding-top: 120px;
    .items {
      display: flex;
      grid-gap: 50px;
      @media screen and (max-width: 1197px) {
        flex-direction: column;
      }
      .item {
        display: flex;
        flex-direction: column;
        grid-gap: 15px;
        width: 100%;
        h4 {
          font-size: 30px;
          line-height: 1.37;
          font-weight: 400;
          @media screen and (max-width: 1197px) {
            text-align: center;
          }
        }
        p {
          color: #808080;
        }

        h5 {
          font-size: 20px;
          line-height: 1.37;
          font-weight: 400;
        }

        ul {
          @media screen and (max-width: 1197px) {
            padding: 20px;
          }

          list-style: disc;
          li {
            margin-top: 10px;
          }
        }
      }
    }
  }
  .name {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 50px;
    h4 {
      font-size: 35px;
      line-height: 1.2;
      font-weight: 400;
    }
    p {
      font-size: 15px;
      line-height: 1.5;
      font-weight: 400;
      margin-top: 15px;
    }
  }

  .benefits {
    display: flex;
    grid-gap: 50px;

    @media screen and (max-width: 873px) {
      flex-direction: column;
      flex-wrap: unset;
      align-items: center;
    }

    @media screen and (max-width: 1150px) {
      flex-wrap: wrap;
    }



    .item {
      display: flex;
      flex-direction: column;
      width: 100%;



      @media screen and (max-width: 1150px) {
        width: 47%;
      }
      @media screen and (max-width: 687px) {
        width: 100%;
        align-items: flex-start;
      }
      p {
        font-size: 15px;
        line-height: 1.5;
        font-weight: 400;
        margin-top: 15px;
      }
      .title {
        display: flex;
        align-items: center;
        h4 {
          font-size: 20px;
          line-height: 1.37;
          font-weight: 400;
          margin-left: 20px;
        }
        .image {
          background: #f5f5f5;
          width: 85px;
          height: 50px;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 10px;
          svg {
            height: 35px;
            width: 35px;
          }
        }
      }
    }

  }
}