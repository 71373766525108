.section {
  padding-top: 50px;
  padding-bottom: 50px;
  .benefits {
    display: flex;
    grid-gap: 50px;
    @media screen and (max-width: 827px) {
      flex-direction: column;
    }
    .item {
      p {
        font-size: 15px;
        line-height: 1.5;
        font-weight: 400;
        margin-top: 15px;
      }
      .title {
        display: flex;
        align-items: center;
        h4 {
          font-size: 20px;
          line-height: 1.37;
          font-weight: 400;
          margin-left: 20px;
        }
        .image {
          background: #f5f5f5;
          width: 50px;
          height: 50px;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 10px;
          svg {
            height: 35px;
            width: 35px;
          }
        }
      }
    }

  }
}