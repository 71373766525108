.section {
  background: #fff;
  border-radius: 15px;
  margin-top: 50px;
  margin-bottom: 50px;
  height: 273px;
  @media screen and (max-width: 1026px){
    height: auto;
    padding-bottom: 30px;
  }

  .text {
    margin-top: 50px;
    margin-left: 50px;
    display: flex;


    @media screen and (max-width: 936px){
      flex-direction: column;
      padding: 30px;
      margin-left: 0;
      margin-top: 0;
    }

    .contact_side {
      width: 60%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      @media screen and (max-width: 936px){
        align-items: flex-start;
      }


      .contact_block {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: max-content;

        @media screen and (max-width: 344px){
          width: 100%;
        }
        .contact_title {

        }
        .contact_list {
          margin-top: 20px;
          display: flex;
          grid-gap: 20px;
          @media screen and (max-width: 344px){
            flex-direction: column;
            align-items: center;
            justify-content: center;
          }


          .contact_item {
            display: flex;
            color: #000;
            svg {
              margin-right: 10px;
              height: 20px;
              width: 20px;
              fill: #000;
            }
          }
        }
      }
    }
    div {
      width: 100%;


      .form {
        display: flex;
        grid-gap: 20px;
        margin-top: 20px;

        @media screen and (max-width: 1026px){
          flex-direction: column;
        }
        @media screen and (max-width: 936px){

        }

        .button {
          display: flex;
          align-items: end;
          .send_button {
            display: flex;
            border: 1px solid #000;
            border-radius: 5px;
            padding: 10px;
            background: #000;

            span {
              display: flex;
              align-items: center;
              color: #fff;
              margin-left: 10px;
              opacity: 1;
              font-size: 16px;
              font-weight: 400;
              svg {
                margin-right: 10px;
                width: 25px;
                height: 25px;
                fill: #fff;
                margin-left: 10px;
              }
            }
          }
        }


        .form_group {
          display: flex;
          flex-direction: column;
          label {
            font-size: 15px;
            line-height: 1.375;
            padding-bottom: 10px;
            font-weight: 500;
            text-align: left;
          }
          input {
            transition: all .3s cubic-bezier(.21,.67,.58,1);
            color: #1b1b1c;

            display: block;
            width: 100%;
            border: 1px solid #bababa;
            min-height: 46px;
            line-height: 1.466;
            font-family: inherit;
            font-size: 15px;
            font-weight: 400;
            padding: 12px 25px 11px 15px;
            word-break: keep-all;
            border-radius: 4px;

            &:hover {
              border: 1px solid #888;
              outline: 0;
              outline-offset: 0;
            }
            &:active {
              border: 1px solid #888;
              outline: 0;
              outline-offset: 0;
            }
            &:focus-visible {
              border: 1px solid #888;
              outline: 0;
              outline-offset: 0;
            }

          }
        }
      }

      h4 {
        font-size: 35px;
        line-height: 1.2;
        font-weight: 500;
        @media screen and (max-width: 430px){
          font-size: 25px;
        }

      }
      p {
        font-size: 15px;
        line-height: 1.5;
        font-weight: 400;
        margin-top: 10px;
      }
      span {
        font-size: 13px;
        line-height: 16px;
        color: #1b1b1c;
        opacity: .6;
      }
    }
  }
}