.section {
  display: flex;
  width: 100%;
  max-height: 205px;
  height: 100%;
  background: #f5f5f5;
  border-radius: 15px;
  margin-top: 200px;

  @media screen and (max-width: 767px) {
    flex-direction: column;
    background: unset;
    margin-top: 150px;
    grid-gap: 40px;
    margin-bottom: 50px;
  }

  @media screen and (max-width: 927px) {
    height: 100px;
  }
  .text {
    width: 100%;
    flex-flow: row wrap;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;

    @media screen and (max-width: 927px) {
      margin: 10px;
    }
    div {
      @media screen and (max-width: 927px) {
        margin-right: 0;
      }
      margin-right: 100px;
    }

    h4 {
      font-weight: 400;
      font-size: 30px;

      @media screen and (max-width: 927px) {

        width: max-content;
      }
    }
    p {
      font-size: 18px;
      line-height: 1.5;
      font-weight: 400;
      @media screen and (max-width: 830px) {
        font-size: 14px;
      }
      @media screen and (max-width: 767px) {
        text-align: center;
      }
    }
  }

  .image {
    width: 100%;
    display: flex;
    align-items: end;

    @media screen and (max-width: 767px) {
      height: 100%;
      background: #f5f5f5;
      border-radius: 15px;
    }

    .bg {
      background-image: url("../assets/images/LoveAccessories.avif");
      height: 300px;
      width: 100%;
      background-repeat: no-repeat;
      background-position-x: 50%;
      background-position-y: bottom;
      position: relative;
      z-index: 1;
      top: 0;
      background-size: 650px;

      @media screen and (max-width: 471px) {
        background-position-y: 100%;
        height: 100%;
        right: 0;
        background-size: 100%;
      }

      @media screen and (max-width: 767px) {
        height: 300px;
        background-size: 290px;
        right: 0;
        background-position-y: bottom;
      }



      @media (min-width: 768px) and (max-width: 1176px) {
        background-position-y: 100%;
        max-height: 250px;
        right: 0;
        background-size: 100%;
      }

    }
  }
}