
:global {
  @media screen and (max-width: 1177px) {
    .wrapper {
      margin-left: 0 !important;
      margin-right: 0 !important;
      padding-left: 20px;
      padding-right: 20px;
    }
  }
}

.about_slider {
  width: 100%;
  div {
    width: 100%;
  }
}

.section {
  display: flex;
  width: 100%;
  //max-height: 287px;
  height: 100%;
  background: #fff;
  border-radius: 15px;
  margin-top: 200px;
  margin-bottom: 50px;

  .fixcol {
    @media screen and (max-width: 756px) {
      flex-direction: column;
    }
  }

  @media screen and (max-width: 842px) {
    flex-direction: column;
  }

  @media screen and (max-width: 532px) {
    flex-direction: column;
    max-height: unset;
  }

  @media screen and (max-width: 641px) {
    margin-top: 100px;
  }


  @media screen and (max-width: 1177px) {
    padding-left: 20px;
    padding-right: 20px;
  }
  @media screen and (max-width: 1075px) {
    max-height: max-content;
  }
  .text {
    width: 100%;
    flex-flow: row wrap;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    margin-left: 50px;


    @media screen and (max-width: 927px) {
      margin: 10px;
    }

    .text_section {

      @media screen and (max-width: 1075px) {
        padding-top: 20px;
        padding-bottom: 20px;
      }

      @media screen and (max-width: 927px) {
        margin-right: 0;
      }
      margin-right: 30px;
      margin-top: 20px;

      p {
        font-size: 15px;
        line-height: 1.5;
        font-weight: 400;
        @media screen and (max-width: 830px) {
          font-size: 14px;
        }

        @media screen and (max-width: 532px) {
          padding-right: 20px;
        }
      }

      .signature {
        display: flex;
        margin-top: 15px;
        @media screen and (max-width: 672px) {
          flex-direction: column;
        }
        .signature_name {
          display: flex;
          flex-direction: column;

          a {
            color: #000;
          }
          span {
            margin-top: 10px;
            display: flex;
            grid-gap: 10px;
            font-size: 14px;
            letter-spacing: 0;
            font-weight: 400;
          }
        }
        .signature_image {
          height: 50px;
          position: relative;
          margin-left: auto;

          img {
            height: 130px;
            @media screen and (max-width: 767px) {
              height: 100px;
            }

          }
        }
      }
    }

    h4 {
      font-weight: 400;
      font-size: 30px;

      @media screen and (max-width: 927px) {
        font-size: 20px;
        line-height: 1.2;
        font-weight: 400;
        width: max-content;
      }
    }

  }

  .image {

    display: flex;
    align-items: end;
    @media screen and (max-width: 842px) {
      justify-content: center;
    }
    .bg {
      background-image: url("../assets/images/Products/IMG_0222-removebg.png");
      height: 350px;
      width: 300px;
      background-repeat: no-repeat;
      background-position-x: 0;
      background-position-y: 50%;
      position: relative;
      z-index: 1;
      top: 0;
      background-size: 250px;


      @media screen and (max-width: 641px) {
        //background-position-y: 100%;
        height: 300px;
        right: 0;
        background-size: 240px;
        margin-top: 30px;
      }

      @media screen and (max-width: 532px) {
        //background-position-y: 100%;
        background-position-x: center;
        height: 250px;
        right: 0;
        background-size: 190px;
      }

      @media (min-width: 831px) and (max-width: 1176px) {
        //background-position-y: 100%;
        max-height: 350px;
        right: 0;
        background-size: 260px;
      }

    }
  }
}

.sale {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  h4 {
    font-size: 141px;
    line-height: 1.2;
    font-weight: 700;
    background-image: linear-gradient(360deg, rgba(237, 237, 237, 0) 0%, rgba(186, 186, 186, 0.43) 100%);
    display: inline-block;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    @media screen and (max-width: 1062px){
      text-align: center;
      font-size: 120px;
    }
  }
  div {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    position: relative;
    bottom: 80px;
    max-width: 689px;
    width: 100%;
    h5 {
      background-image: linear-gradient(135deg, #737373 44.54%, #000000 100%);
      display: inline-block;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      font-size: 65px;
      line-height: 1.2;
      font-weight: 700;
      @media screen and (max-width: 1062px){
        text-align: center;
      }
    }
    p {
      text-align: center;
      font-size: 15px;
      line-height: 1.5;
      font-weight: 400;
      margin-top: 10px;
    }
  }


}

.service {
  display: flex;
  margin-top: 100px;
  grid-gap: 50px;
  @media screen and (max-width: 1022px) {
    flex-direction: column;
  }
  .benefits {
    display: flex;
    flex-direction: column;
    grid-gap: 15px;
    width: 100%;
    @media screen and (max-width: 1022px) {
      align-items: center;
    }
    .item {
      display: flex;
      flex-direction: column;
      justify-content: center;
      background: #fff;
      height: auto;
      border-radius: 15px;
      max-width: 576px;

      padding: 30px;
      width: 100%;
      h4 {
        font-size: 22px;
        line-height: 1.37;
        font-weight: 400;
      }

      ul {
        list-style: disc;
        li {
          color: #808080;
          font-size: 15px;
          line-height: 1.5;
          font-weight: 400;
          margin-top: 10px;
          a {
            color: #000;
          }
          &::marker {
            color: #808080;
          }
        }
      }

    }
  }
  .start {
    width: 100%;
    display: flex;
    align-items: flex-start;
    .start_section {
      display: flex;
      align-items: center;
      width: 100%;
      justify-content: center;
      flex-direction: column;
      .items {
        display: flex;
        margin-top: 30px;
        margin-bottom: 20px;
        @media screen and (max-width: 936px){
          flex-direction: column;
        }


        .image {
          max-height: 100px;
          max-width: 130px;
        }
      }
      span {
        display: flex;
        align-items: center;
        font-size: 35px;
        line-height: 1.2;
        font-weight: 400;
        @media screen and (max-width: 500px){
          flex-direction: column;
        }

        strong {
          margin-left: 15px;
          color: #fff;
          background: linear-gradient(135deg, #b5d1ff 0%, #f57070 100%);
          border-radius: 10px;
          font-size: 22px;
          line-height: 1.5;
          font-weight: 400;
          padding: 13px;
        }
      }
    }
  }
}