.section {
  width: 100%;
  max-height: 100%;
  height: 100%;
  border-radius: 15px;
  margin-top: 200px;
  padding-bottom: 20px;
  @media screen and (max-width: 425px) {
    margin-top: 140px;
  }

  .title {
    display: flex;
    @media screen and (max-width: 425px) {
      flex-direction: column;
    }
  }

  .faq {
    display: flex;
    align-items: end;
    justify-content: end;
    text-align: end;
    width: 100%;
    @media screen and (max-width: 425px) {
      justify-content: flex-start;
    }

    span {
      cursor: pointer;
      justify-content: end;
      svg {
        height: 25px;
      }

      &:hover {
        color: #FB6590;
        svg {
          fill: #FB6590;
        }
      }
    }


  }

  div {
    p {
      @media screen and (max-width: 425px) {
        font-size: 15px;
      }
    }
    span {
      font-size: 15px;
      line-height: 1.37;
      font-weight: 400;
      width: fit-content;
      display: flex;
      align-items: center;
      margin-left: 10px;
      margin-top: 20px;
      cursor: pointer;

      @media screen and (max-width: 425px) {
        margin-left: 0;
      }

      svg {
        height: 25px;
      }
      &:hover {
        color: #FB6590;
        svg {
          fill: #FB6590;
        }
      }

    }

    h1 {
      font-size: 35px;
      line-height: 1.2;
      font-weight: 400;
      width: 120%;
      @media screen and (max-width: 425px) {
        font-size: 28px;
        width: 100%;
      }
    }
  }

  .items {
    display: flex;
    width: 100%;
    grid-gap: 40px;
    margin-top: 40px;
    padding-bottom: 20px;
    @media screen and (max-width: 425px) {
      grid-gap: 25px;
    }

    a {
      height: 317px;
      width: 100%;
      border-radius: 15px;
      color: #000;
      @media screen and (max-width: 425px) {
        &:first-child {
          width: 55%;
          height: 200px;

        }
        &:last-child {
          width: 35%;
          height: 137px;
          margin-top: auto;
        }
      }
      :hover {

          span {
            color: #FB6590;
          }
          svg {
            fill: #FB6590;
          }

      }
      div {
        height: 100%;

        .item {
          width: 100%;
          display: flex;
          justify-content: center;
          background: #f5f5f5;

          @media screen and (max-width: 425px) {
            height: 100%;
          }

        }
      }
    }

  }
}