:global {

  .react-slideshow-container {
    width: 340px;
    .default-nav {
      background: transparent !important;

        svg {
          fill: #979797;
        }

    }
  }
}

.product_section {
  width: 100%;
  max-height: 100%;
  height: 100%;
  padding-top: 140px;
  padding-bottom: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .product_items {
    width: 100%;
    @media screen and (min-width: 1023px) {
      width: 958px;
    }

    justify-content: center;

    .product_item {
      background: #fff;
      border-radius: 15px;
      margin-bottom: 30px;
      height: auto;
      display: flex;

      @media screen and (max-width: 1023px) {
        flex-direction: column;
        height: auto;
      }

      .p_image {
        display: flex;
        justify-content: center;
        padding: 10px;
        height: 93%;
        align-items: center;
        @media screen and (max-width: 1023px) {
          max-width: unset;
        }

        max-width: 360px;
        width: 100%;
        img {
          height: 93%;
          width: auto;
          @media screen and (max-width: 1023px) {
            height: 300px;
          }

        }
      }

      .p_info {
        margin-right: 30px;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;

        @media screen and (max-width: 1023px) {
          padding-left: 30px;
          margin-right: 0;
          padding-right: 30px;
        }

        .code {
          padding-top: 20px;
          font-size: 12px;
          font-weight: 400;
          line-height: 1.37;
          color: #888;
          opacity: 100%;
        }

        .p_title {
          font-size: 24px;
          font-weight: 400;
          line-height: 1.37;
          color: #000;
          margin-top: 20px;
          display: flex;
          .reviews {
            margin-left: auto;
            color: #000;
            p {
              font-size: 18px;
              font-weight: 400;
              line-height: 1.37;

            }
          }

        }

        .p_desc {
          margin-top: 10px;
          font-size: 15px;
          font-weight: 400;
          line-height: 1.2;
        }

        .p_memory {
          margin-top: 20px;
          p {
            font-size: 14px;
            line-height: 18px;
            font-weight: 500;
            margin-bottom: 10px;
            color: #888;
          }

          .p_memory_switch {
            .payment {
              margin-top: 20px;
            }

            .p_memory_ul {
              display: flex;
              flex-direction: row;
              grid-gap: 10px;


              li {
                border: 1px solid #cbcbcb;
                border-radius: 5px;
                cursor: pointer;
                font-size: calc(11px + calc((100 / 25) - 3) * 1px);
                padding: 7px 14px 7px 14px;
                display: flex;
                align-items: center;
                grid-gap: 5px;

                @media (max-width: 476px) {
                  flex-direction: column;
                  justify-content: center;
                  align-items: center;
                  text-align: center;
                }
                img {
                  height: 20px;
                }
                @media screen and (max-width: 1023px) {
                  padding: 7px 10px 7px 10px;
                }
                &.active {
                  border: 2px solid #000;

                  &:hover {
                    border: 2px solid #000;
                  }
                }

                &:hover {
                  border: 1px solid #7e7e7e;
                }
              }
            }

          }
        }

        .p_color {
          margin-top: 20px;
          p {
            font-size: 14px;
            line-height: 18px;
            font-weight: 500;
            margin-bottom: 10px;
            color: #888;
          }

          .p_color_switch {
            display: flex;
            grid-gap: 10px;

            .p_color_set {
              cursor: pointer;
              height: 25px;
              width: 25px;
              border-radius: 5px;
              display: flex;
              justify-content: center;
              align-items: center;

              svg {
                display: none;

                &.active {
                  display: flex;
                }
              }
            }
          }
        }

        .p_cost {
          margin-top: 50px;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          padding-bottom: 30px;
          @media screen and (max-width: 1023px) {
            margin-top: 30px;
            padding-bottom: 30px;
          }
          .buy {
            display: flex;
            border: 1px solid #000;
            border-radius: 5px;
            padding: 5px;
            background: #000;
            transition: all 0.2s ease-in-out;
            position: absolute;
            cursor: pointer;
            &:hover {
              padding: 7px;
            }

            span {
              display: flex;
              color: #fff;
              margin-left: 10px;
              svg {
                margin-right: 5px;
                margin-left: 10px;
                width: 25px;
                height: 25px;
                fill: #fff;
              }
            }
          }
          .p_show_cost {
            display: flex;
            grid-gap: 5px;
            margin-left: auto;
            align-items: center;
            h4 {
              font-size: 18px;
              font-weight: 400;
              line-height: 1.37;
            }
          }
        }
      }
    }
  }

  .title_info {
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding-bottom: 80px;
    @media screen and (min-width: 1023px) {
      align-items: center;
    }
    .title_svg {
      height: 37px;
      min-width: 37px;
      border-radius: 50%;
      background: #f04343;
      display: flex;
      justify-content: center;
      align-items: center;

      svg {
        fill: #fff;
        height: 24px;
        width: 24px;
      }
    }

    h4 {
      color: #000;
      font-size: 16px;
      line-height: 1.5;
      font-weight: 400;
      margin-left: 15px;
    }
  }
}