.section {
  padding-top: 70px;
  padding-bottom: 70px;



  @media (min-width: 426px) and (max-width: 1171px) {
    margin-left: 20px;
    margin-right: 20px;
  }

  .logo {
    img {
      height: 50px;
    }
    @media screen and (max-width: 425px) {
      margin-left: 20px;
    }
  }

  .footer_content {
    display: flex;
    margin-top: 40px;

    @media screen and (max-width: 827px) {
      flex-direction: column;
      margin-left: 20px;
      margin-right: 20px;
    }

    .footer_br {
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 100%;

      @media screen and (max-width: 827px) {
        margin-top: 30px;
      }

      .desc {
        margin-top: 80px;
        font-size: 12px;
        line-height: 1.5;
        font-weight: 400;
        color: #4a4a4a;
      }

    }

    .lists {
      display: flex;
      grid-gap: 100px;

      @media screen and (max-width: 827px) {
        grid-gap: 50px;
      }

      width: 100%;
      .list {
        p {
          color: #888888;
          font-size: 15px;
          line-height: 1.5;
          font-weight: 400;
          margin-bottom: 10px;
        }

        li {
          color: #fff;
          width: 100%;
          font-size: 15px;
          line-height: 1.37;
          font-weight: 400;
          margin-bottom: 10px;
          letter-spacing: 0;
          opacity: 1;
          transition: all .3s ease;
          text-decoration-color: rgba(255, 255, 255, 0.5);
          cursor: pointer;

          &:hover {
            text-underline-offset: 8px;
            text-decoration-line: underline;

          }
        }
      }
    }
  }

  .contact {
    display: flex;
    align-items: center;

    @media screen and (max-width: 827px) {
      flex-direction: column;
      align-items: flex-start;
    }

    .links {
      display: flex;
      grid-gap: 5px;
      margin-left: 50px;

      @media screen and (max-width: 827px) {
        margin-top: 15px;
        grid-gap: 7px;
      }

      .icon {
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 32px;
        width: 32px;
        background: #888888;
        border-radius: 5px;

        svg {
          height: 17px;
          width: 17px;
        }
      }
    }
    .phone {
      display: flex;
      div {
        display: flex;
        flex-direction: column;
        width: max-content;
      }

      .chat {
        display: flex;
        justify-content: flex-start;

        svg {
          @media screen and (max-width: 827px) {
            height: 40px;
            width: 40px;
            margin-right: 10px;
          }
        }
      }

      .tel {
        color: #fff;
        font-size: 20px;
        line-height: 1.5;
        font-weight: 500;

      }
      span {
        color: #fff;
        font-size: 15px;
        line-height: 1.5;
        font-weight: 400;
      }
    }

  }
}